<template>
    <div class="page">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="page-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/notice' }">公告列表</el-breadcrumb-item>
            <el-breadcrumb-item>信息详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="newestNoticSty">
            <div style="padding: 30px 30px;">
                <div  class="title">{{noticeDetails.title}}</div>

                <el-divider></el-divider>

                <p class="time">{{noticeDetails.createTime}}</p>
                <br>
                <div v-html="noticeDetails.content" style="text-align: left;margin-left: 80px;margin-right: 80px">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        noticeDetails
    } from "@/api/index.js"

    export default {
        data() {
            return {
                noticeDetails: {}
            }
        },
        methods: {
            getInfo() {
                let param = {
                    id: this.$route.query.id
                }
                noticeDetails(param).then(res => {
                    if (res.code == "0000") {
                        // console.log(res.data)
                        this.noticeDetails = res.data
                    }
                })
            }
        },
        mounted() {
            this.getInfo()
        }
    }
</script>

<style lang="scss" scoped>

	.page-breadcrumb {
		padding: 15px 0;
        cursor: pointer;
	}
    .newestNoticSty {
        width: 1200px;
        background-color: #FFFFFF;
        margin: 0 auto;
        border-radius: 4px;
        box-shadow: 10px 10px 5px #cccccc;

		.title{
			text-align: center;
			font-size: 36px;
			font-weight: 700;
			line-height: 50px;
		}
		.time{
			text-align: center;
			font-size: 16px;
			color: #808080;
			line-height: 30px;
		}

    }
</style>
